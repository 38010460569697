/* eslint-disable consistent-return */
import type { StackProps } from '@chakra-ui/react';
import {
  Box,
  Collapse,
  Flex,
  HStack,
  Radio,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { type FC } from 'react';

// const GAS_OPTIONS_TO_NAMES: Record<string, string> = {
//   '1': 'flexible',
//   '2': 'immediate',
//   '3': 'future',
//   '4': 'custom',
// };

export const GasOption: FC<
  {
    value: string;
    title: string;
    icon: React.ReactNode;
    isChecked: boolean;
    isDisabled?: boolean;
    usd: string;
    gwei: string;
    description?: React.ReactNode;
  } & Omit<StackProps, 'title'>
> = ({
  title,
  value,
  icon,
  isChecked,
  isDisabled,
  usd,
  gwei,
  description,
  ...boxProps
}) => {
  // const {
  //   fct: {
  //     analytics: { fctConfig },
  //   },
  // } = useAccount();

  // useEffect(() => {
  //   if (isChecked) {
  //     fctConfig.setSelectedGasOption(GAS_OPTIONS_TO_NAMES[value]);
  //     fctConfig.setMaxGasPriceGwei(gwei);
  //     fctConfig.setSelectedGasDollar(usd);
  //   }
  // }, [isChecked, fctConfig, gwei, usd, value]);
  const t = useTranslations();

  if (!isChecked && isDisabled) {
    return null;
  }

  return (
    <Flex
      flexDir="column"
      w="full"
      whiteSpace="nowrap"
      bg={isChecked ? '#F7F7F7' : ''}
      _dark={{ bg: isChecked ? '#080B0E' : '' }}
      borderRadius="md"
      {...boxProps}
      rounded="12px"
      p="12px"
      pr="20px"
      sx={{
        '.chakra-radio__label': {
          w: 'full',
        },
      }}
    >
      {isDisabled ? (
        <HStack cursor="not-allowed">
          {icon}
          <Text fontSize="sm">{title}</Text>
          <Spacer />
          <HStack align="center" spacing="1">
            {usd && usd !== '0' ? (
              <Text fontSize="md">
                {t.rich('up-to', {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  x: () => (
                    <Text as="span" fontWeight="semibold">
                      {usd}
                    </Text>
                  ),
                })}
                <Text as="span" fontWeight="semibold">
                  {usd}
                </Text>
              </Text>
            ) : (
              <Spinner size="xs" />
            )}
            {gwei && (
              <Text fontSize="xs" color="gray.500">
                {gwei} GWEI
              </Text>
            )}
          </HStack>
        </HStack>
      ) : (
        <Radio
          autoFocus={false}
          w="full"
          display="flex"
          flex="1"
          size="lg"
          value={value}
          onClick={(ev) => {
            if (ev.target instanceof HTMLInputElement) {
              return ev.preventDefault();
            }
          }}
          isChecked={isChecked}
          isDisabled={isDisabled}
        >
          <Box flex="1">
            <HStack>
              {icon}
              <Text fontSize="sm">{title}</Text>
              <Spacer />
              <HStack align="center" spacing="1">
                {usd && (
                  <Text fontSize="md">
                    {t.rich('up-to', {
                      // eslint-disable-next-line react/no-unstable-nested-components
                      x: () => (
                        <Text as="span" fontWeight="semibold">
                          {usd}
                        </Text>
                      ),
                    })}
                    <Text as="span" fontWeight="semibold">
                      {usd}
                    </Text>
                  </Text>
                )}
                {gwei && (
                  <Text fontSize="xs" color="gray.500">
                    {gwei} GWEI
                  </Text>
                )}
              </HStack>
            </HStack>
          </Box>
        </Radio>
      )}

      <Collapse in={isChecked}>
        {(typeof description === 'string' && (
          <Text
            maxW="360px"
            whiteSpace="initial"
            color="gray.400"
            mt="5px"
            ml="32px"
            fontSize="sm"
          >
            {description}
          </Text>
        )) ||
          description}
      </Collapse>
    </Flex>
  );
};
